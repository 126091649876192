import React from 'react';
import { Box, Typography, FormControl, Divider } from '@mui/material';
import { getApolloReferral, setApolloReferralStatus } from '../../api';
import useFetch from '../../hooks/useFetch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

const TealReferral = ({ patientId, username, token }) => {
    const { data: patient, loading, error } = useFetch(getApolloReferral, patientId, { username, token});
    const [referrals, setReferrals] = React.useState([]);

    React.useEffect(() => {
        if (patient && patient.Referrals) {
            setReferrals(patient.Referrals);
        }
    }, [patient]);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error: {error.message}</Typography>;

    const handleAckRef = (event, referralID) => {
        setApolloReferralStatus(patientId, { username, token}, referralID, 'ACK');
        
        setReferrals(prevState => prevState.map(ref =>
            ref.referralID === referralID ? { ...ref, Status: 'ACK' } : ref
        ));
    };

    if (patient.Active === 0) {
        return (<div></div>)
    }

    let refComponent = (<div>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
            <TableBody>
                {referrals.map((ref, index) => {
                    let alertColor = "";

                    let statusForm = (
                            <FormControl variant="standard" sx={{ m: 0, minWidth: 46 }}>
                                  <Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    onChange={(event) => handleAckRef(event, ref.ReferralID)}
                                  />
                            </FormControl>
                    );

                    return (
                        <React.Fragment key={ref.referralID}>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '3px', bgcolor: 'darkgray' }} component="th" scope="row"></TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '8px', bgcolor: 'lightgray' }} component="th" scope="row">
                            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                                {ref.ClinicalOrderType} 
                            </Typography>
                            </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '8px', bgcolor: alertColor }} align="left">
                                <Box display="flex" alignItems="center">
                                <Box sx={{ flexGrow: 1 }}>
                                    Provider: {ref.ReceivingProvider}<br/>Scheduled DOS: {ref.ScheduledDateOfService}<br/>Referral Created: {ref.CreatedDate} <br />
                                </Box>
                                {statusForm}
                                </Box>
                            </TableCell>
                            </TableRow>
                        </React.Fragment>
                        )
                    }
                )
            }
            </TableBody>
            </Table>
        </TableContainer>
    </div>)

    return (
        <div>
            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ backgroundColor: '#d5dbdb', margin: '0 !important', minHeight: 'unset !important', '& .MuiAccordionSummary-content': { margin: '5px 0 !important' } }}
                >
                    <Typography variant="h6">Pending Referrals</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#eaeded', padding: '12px' }}>
                    {refComponent}
                </AccordionDetails>
            </Accordion>
            <Divider sx={{ height:10 }}/>
        </div>
    );
}

export default TealReferral;
