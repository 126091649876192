import React from 'react';
import { Box, Typography, Divider, Card } from '@mui/material';
import { getApolloUtilization } from '../../api';
import useFetch from '../../hooks/useFetch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TealUtilization = ({ patientId, username, token }) => {

    const { data: patient, loading, error } = useFetch(getApolloUtilization, patientId, { username, token});
    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error: {error.message}</Typography>;

    try {
        if (patient.Active === 0) {
            return (<div></div>)
        }

        let admissionTable = (<></>)
        if (patient.Utilization.Admission.Last12Month !== 0) {
            admissionTable = (<TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead sx={{ bgcolor: 'lightgray' }}>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ padding: '3px', bgcolor: 'darkgray' }} component="th" scope="row" colSpan={2}></TableCell>
                    </TableRow>
                    <TableCell sx={{ padding: '8px', bgcolor: 'lightgray' }} component="th" scope="row" colSpan={2}>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                            Admissions
                        </Typography>
                    </TableCell>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '8px', minWidth: '120px' }} component="th" scope="row">Last 12 Months</TableCell>
                            <TableCell sx={{ padding: '8px' }} align="right">{patient.Utilization.Admission.Last12Month}</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '8px' }} component="th" scope="row">Last Date</TableCell>
                            <TableCell sx={{ padding: '8px' }} align="right">{patient.Utilization.Admission.LastDate}</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '8px' }} component="th" scope="row">Admit Diagnosis</TableCell>
                            <TableCell sx={{ padding: '8px' }} align="right">{patient.Utilization.Admission.Diagnosis}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>)
        }
        

        let erTable = (<></>)
        if (patient.Utilization.ER.Last12Month !== 0) {
            erTable = (<TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead sx={{ bgcolor: 'lightgray' }}>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ padding: '3px', bgcolor: 'darkgray' }} component="th" scope="row" colSpan={2}></TableCell>
                    </TableRow>
                    <TableCell sx={{ padding: '8px', bgcolor: 'lightgray' }} component="th" scope="row" colSpan={2}>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                            ER Visits
                        </Typography>
                    </TableCell>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '8px', width: '108px' }} component="th" scope="row">Last 12 Months</TableCell>
                            <TableCell sx={{ padding: '8px' }} align="right">{patient.Utilization.ER.Last12Month}</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '8px' }} component="th" scope="row">Last Date</TableCell>
                            <TableCell sx={{ padding: '8px' }} align="right">{patient.Utilization.ER.LastDate}</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '8px' }} component="th" scope="row">Admit Diagnosis</TableCell>
                            <TableCell sx={{ padding: '8px' }} align="right">{patient.Utilization.ER.Diagnosis}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>)
        }

        return (
            <div>
            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ backgroundColor: '#d5dbdb', margin: '0 !important', minHeight: 'unset !important', '& .MuiAccordionSummary-content': { margin: '5px 0 !important' } }}
                >
                <Typography variant="h6">Utilization</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#eaeded', padding: '12px' }}>
                {admissionTable}
                {erTable}
                </AccordionDetails>
            </Accordion>
            <Divider sx={{ height:15 }}/>
            </div>
        )
    } catch (err) {
        console.log(err);
        return <div></div>;
    }
}

export default TealUtilization;
