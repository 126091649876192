import React, { useState, useEffect } from 'react';
import { setCodingPatientTaskReview, addApolloDiseaseDetection, addCodingApolloDiseaseDetection, getCodingDiagnosisAutosuggest } from '../../api';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Box,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete } from '@mui/material';
import dayjs from 'dayjs';

const TaskModal = ({ username, token, openModal, handleCloseModal, selectedTask }) => {
  let task = selectedTask[0];
  let action = selectedTask[1];

  const [comment, setComment] = useState('');
  const [apolloComment, setApolloComment] = useState('');
  const [diagnosisHCC, setDiagnosisHCC] = useState('');
  const [expirationAt, setExpirationAt] = useState(dayjs().add(6, 'month'));
  const [actionTaken, setActionTaken] = useState('');
  const [isApolloAdded, setIsApolloAdded] = useState(false);
  const [suggestions, setSuggestions] = useState([]); // To store suggestions from the API

  useEffect(() => {
    if (task && task.task_json_obj) {
      setDiagnosisHCC(`${task.task_json_obj.diagnosis_code}/${task.task_json_obj.hcc}`);
    }
  }, [task]);

  const fetchSuggestions = async (input) => {
    try {
      const response = await getCodingDiagnosisAutosuggest(
        { username, token },
        input
      );
      setSuggestions(response || []);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleCommentChange = (event) => setComment(event.target.value);
  const handleApolloCommentChange = (event) => setApolloComment(event.target.value);
  const handleActionTakenChange = (event) => setActionTaken(event.target.value);

  const handleSubmitReview = async () => {
    await setCodingPatientTaskReview(
      { username, token },
      task,
      action === 'dismiss' ? 'dismissed' : actionTaken,
      comment,
      expirationAt.format('YYYY-MM-DD')
    );
    handleResetAndCloseModal();
  };

  const handleSendToApollo = () => {
    const timestampInMilliseconds = Date.now();
    const timestampString = timestampInMilliseconds.toString();
    addApolloDiseaseDetection(task.patient_id, { username, token }, diagnosisHCC, apolloComment, timestampString);
    addCodingApolloDiseaseDetection(task.patient_id, { username, token }, diagnosisHCC, apolloComment, timestampString);
    setIsApolloAdded(true);
  };

  const handleResetAndCloseModal = () => {
    setComment('');
    setApolloComment('');
    setDiagnosisHCC('');
    setExpirationAt(dayjs().add(6, 'month'));
    setActionTaken('');
    setIsApolloAdded(false);
    handleCloseModal();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        open={openModal}
        onClose={handleResetAndCloseModal}
        maxWidth={false}
        PaperProps={{ sx: { width: '600px' } }}
      >
        <DialogTitle>{action === 'dismiss' ? 'Dismiss Diagnosis' : 'Task Details'}</DialogTitle>
        <DialogContent>
          {task ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body1" fontWeight="bold">Patient ID:</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{task.patient_id}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body1" fontWeight="bold">Review Type:</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{task.disease_type}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body1" fontWeight="bold">Diagnosis:</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{task.task_json_obj.diagnosis_code}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="h6" sx={{ marginTop: 2 }}>
                {action === 'dismiss' ? 'Dismissal Reason' : 'Comments'}
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                label={action === 'dismiss' ? 'Add your reason for dismissal' : 'Add your comment'}
                value={comment}
                onChange={handleCommentChange}
                sx={{ marginTop: 1 }}
              />

              {action === 'accept' && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel>Action Taken</InputLabel>
                    <Select
                      value={actionTaken}
                      onChange={handleActionTakenChange}
                      label="Action Taken"
                    >
                      <MenuItem value="everything good">Everything looks good</MenuItem>
                      <MenuItem value="dismissed">Dismissed</MenuItem>
                      <MenuItem value="add code">Recommend to add code</MenuItem>
                      <MenuItem value="remove code">Recommend to remove code</MenuItem>
                      <MenuItem value="update code">
                        Update code for coding guidelines
                      </MenuItem>
                      <MenuItem value="additional tests">
                        Recommend additional tests/screening
                      </MenuItem>
                      <MenuItem value="medical records">
                        Recommend to collect medical records
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <Paper
                    elevation={3}
                    sx={{
                      marginTop: 2,
                      padding: 2,
                      backgroundColor: 'lightcyan',
                      borderRadius: 2,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Apollo Recommendation
                    </Typography>
                    <Autocomplete
                      freeSolo
                      value={diagnosisHCC} // Controlled value for the Autocomplete
                      options={suggestions} // Options for autocomplete suggestions
                      onInputChange={(event, value) => {
                        setDiagnosisHCC(value); // Update the controlled state
                        fetchSuggestions(value); // Fetch new suggestions
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Diagnosis/HCC"
                          sx={{ marginTop: 1 }}
                        />
                      )}
                    />
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      label="Enter Apollo recommendation"
                      value={apolloComment}
                      onChange={handleApolloCommentChange}
                      sx={{ marginTop: 1 }}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSendToApollo}
                      disabled={isApolloAdded}
                      sx={{ marginTop: 1 }}
                    >
                      {isApolloAdded ? 'Added To Apollo' : 'Send to Apollo'}
                    </Button>
                  </Paper>
                </>
              )}
            </>
          ) : (
            <Typography>No task selected</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetAndCloseModal} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitReview}
            disabled={action === 'accept' && !actionTaken}
          >
            Submit Review
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default TaskModal;
