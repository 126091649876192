import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { getAuth, login, resetPassword } from '../../api';
import MuiAlert from '@mui/material/Alert';
import Dashboard from './Dashboard';
import { useParams } from 'react-router-dom';

import {
  TextField, Box, Button, Typography, Snackbar,
  AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import VisitDetails from './VisitDetails';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CodingFlowHome = () => {
  const { page } = useParams();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [beta, setBeta] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [state, setState] = React.useState({
    openAlert: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [drawerOpen, setDrawerOpen] = useState(false);  // Control Drawer open/close state

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));  // Detect mobile screens

  const { vertical, horizontal, openAlert } = state;

  useEffect(() => {
    const token = Cookies.get('authToken');
    const username = Cookies.get('username');
    if (token && token !== '') {
      setAuthToken(token);
      setUsername(username);
    }
  }, []);

  useEffect(() => {
    const authenticate = async () => {
      if (authToken) {
        const token = authToken;
        try {
          const auth = await getAuth({ username, token });
          if (auth.token === '') {
            Cookies.remove('authToken');
            setAuthToken('');
          } else {
            setBeta(auth.beta);
          }
        } catch (error) {
          console.error('Error during authentication:', error);
        }
      }
    };

    authenticate();
  }, [authToken, username]);

  const handleLogin = async () => {
    try {
      const data = await login({ 'user': username, 'password': password });
      if (data.token) {
        Cookies.set('username', username, { expires: 5 });
        Cookies.set('authToken', data.token, { expires: 5 });
        setAuthToken(data.token);
        setBeta(data.beta);
        if (data.reset && data.reset === 1) {
          setResetPass(true);
          setPassword('');
        } else {
          setResetPass(false);
        }
      }
    } catch (error) {
      console.error('Login failed', error);
      setState({ ...state, openAlert: true });
    }
  };

  const handleReset = async () => {
    try {
      const data = await resetPassword({ username, password, authToken });
      if (data.token) {
        Cookies.set('authToken', data.token, { expires: 1 });
        setAuthToken(data.token);
        setBeta(data.beta);
        if (data.reset && data.reset === 1) {
          setResetPass(true);
        } else {
          setResetPass(false);
        }
      } else {
        console.error('Login failed', data.message);
        setState({ ...state, openAlert: true });
      }
    } catch (error) {
      console.error('Login failed', error);
      setState({ ...state, openAlert: true });
    }
  };

  const handleLogout = () => {
    Cookies.remove('authToken');
    setAuthToken('');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ ...state, openAlert: false });
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={() => setDrawerOpen(false)}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  const header = (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Coding Flow Dashboard
        </Typography>
      </Toolbar>
    </AppBar>
  );

  if (resetPass) {
    return (
      <div style={{ maxWidth: '430px', margin: '0 auto' }}>
        {header}
        <Typography variant="h5">Reset Password</Typography>
        <TextField
          label="New Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleReset}>
          Set New Password
        </Button>
      </div>
    );
  }

  if (authToken !== '') {
    let content = (<></>)
    
    if (page) {
      content = (
        <VisitDetails username={username} token={authToken} />
      );
    } else {
      content = (<Dashboard username={username} token={authToken} />)
    }

    return (
      <div>
        {header}
        <Drawer anchor={isMobile ? 'bottom' : 'left'} open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerContent}
        </Drawer>
        <div style={{ maxWidth: '90%', margin: '0 auto', marginBottom: '50px' }}>
          {content}
        </div>
      </div>
    );
  }

  if (!authToken || authToken === '') {
    return (
      <div style={{ maxWidth: '430px', margin: '0 auto' }}>
        {header}
        <Typography variant="h5">Login</Typography>
        <TextField
          label="Username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
          <Alert onClose={handleClose} severity="error">
            Login failed
          </Alert>
        </Snackbar>
      </div>
    );
  }
};

export default CodingFlowHome;
