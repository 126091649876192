import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import TealHeader from './TealHeader';
import TealUtilization from './TealUtilization';
import TealReferral from './TealReferral';
import TealMedicationAdherence from './TealMedicationAdherence';
import { useParams } from 'react-router-dom';
import TealDiseaseDetection from './TealDiseaseDetection';
import { getAuth, login, resetPassword } from '../../api';
import MuiAlert from '@mui/material/Alert';
import Feedback from './Feedback';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { TextField, Box, Button, Typography, Snackbar } from '@mui/material';
import TealQualityDiabetes from './TealQualityDiabetes';
import TealQualityScreening from './TealQualityScreening';
import TealScreeners from './TealScreeners';
import CoralHeader from './CoralHeader';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PatientInfo = () => {
  let { pid, user } = useParams();

  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [beta, setBeta] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [state, setState] = React.useState({
    openAlert: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [widgetWidth, setWidgetWidth] = React.useState('430');

  const theme = useTheme();
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    try {
      if (newValue === 2) {  // Coding
          setWidgetWidth('880');
          window.parent.postMessage("900", 'https://athenanet.athenahealth.com');
          console.log("sending width 900");
      } else {
         setWidgetWidth('430');
          window.parent.postMessage("450", 'https://athenanet.athenahealth.com');
          console.log("sending width 450");
      }
    } catch (error) {
      console.error('Failed to send message to parent:', error);
    }
  };

  const { vertical, horizontal, openAlert } = state;

  useEffect(() => {
    const token = Cookies.get('authToken');
    const username = Cookies.get('username');
    if (token && token !== '') {
      setAuthToken(token);
      setUsername(username);
    }
  }, []);

  useEffect(() => {
    const authenticate = async () => {
      if (authToken) {
        const username = user;
        const token = authToken;

        try {
          const auth = await getAuth({ username, token });
          if (auth.token === '') {
            Cookies.remove('authToken');
            setAuthToken('');
          } else {
            setBeta(auth.beta);
          }
        } catch (error) {
          console.error('Error during authentication:', error);
        }
      }
    };

    authenticate();
  }, [authToken, user]);

  const handleLogin = async () => {
    try {
      const data = await login({ user, password });
      if (data.token) {
        if (process.env.NODE_ENV === 'production') {
          Cookies.set('username', user, { expires: 5, sameSite: 'None', secure: true });
          Cookies.set('authToken', data.token, { expires: 5, sameSite: 'None', secure: true }); // Expires in 5 days hours
        } else {
          Cookies.set('username', user, { expires: 5 });
          Cookies.set('authToken', data.token, { expires: 5 }); // Expires in 5 days hours
        }
        setAuthToken(data.token);
        setBeta(data.beta);
        if (data.reset && data.reset === 1) {
          setResetPass(true);
          setPassword('');
        } else {
          setResetPass(false);
        }
      }
    } catch (error) {
      console.error('Login failed', error);
      setState({ ...state, openAlert: true });
    }
  };

  const handleReset = async () => {
    try {
      const data = await resetPassword({ user, password, authToken });
      if (data.token) {
        if (process.env.NODE_ENV === 'production') {
          Cookies.set('username', username, { expires: 1, sameSite: 'None', secure: true });
          Cookies.set('authToken', data.token, { expires: 1, sameSite: 'None', secure: true }); // Expires in 24 hours
        } else {
          Cookies.set('username', username, { expires: 1 });
          Cookies.set('authToken', data.token, { expires: 1 }); // Expires in 24 hours
        }
        setAuthToken(data.token);
        setBeta(data.beta);
        if (data.reset && data.reset === 1) {
          setResetPass(true);
        } else {
          setResetPass(false);
        }
      } else {
        console.error('Login failed', data.message);
        setState({ ...state, openAlert: true });
      }
    } catch (error) {
      console.error('Login failed', error);
      setState({ ...state, openAlert: true });
    }
  };

  const handleProblem = () => {
    setIsFeedbackOpen(true);
  };

  const handleCloseFeedback = () => {
    setIsFeedbackOpen(false);
  };

  const handleLogout = () => {
    Cookies.remove('authToken');
    setAuthToken('');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ ...state, openAlert: false });
  };

  if (resetPass) {
    return (
      <div style={{ maxWidth: '430px', margin: '0 auto' }}>
        <Typography variant="h5">Reset Password</Typography>
        <TextField
          label="New Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleReset}>
          Set New Password
        </Button>
      </div>
    );
  }

  if (authToken !== '') {
    let module = {
      title: 'Report a problem',
      feedback_description: 'Problem Description',
      module_category: 'AppFeedback',
      module_name: 'Problems',
      module_item_key: '',
    };

    if (beta) {      
      return (
        <div style={{ maxWidth: widgetWidth + 'px', margin: '0 auto', marginBottom: '50px' }}>
          <AppBar position="static">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="fullWidth"
              aria-label="full width tabs"
              sx={{
                backgroundColor: '#CCCCCC',
                minHeight: '36px',
                '& .MuiTabs-flexContainer': {
                  minHeight: '36px',
                },
                '& .MuiTabs-indicator': {
                  minHeight: '4px',
                  backgroundColor: '#A5D8FF',
                },
              }}
            >
              <Tab
                label="Clinical"
                {...a11yProps(0)}
                sx={{
                  minHeight: '36px',
                  padding: '4px 8px',
                  fontSize: '0.8rem',
                  color: '#fff',
                  backgroundColor: '#00B0B0',
                  '&.Mui-selected': {
                    color: '#fff',
                    backgroundColor: '#008080',
                  },
                }}
              />
              <Tab
                label="Operations"
                {...a11yProps(1)}
                sx={{
                  minHeight: '36px',
                  padding: '4px 8px',
                  fontSize: '0.8rem',
                  color: '#fff',
                  backgroundColor: '#f8907a',
                  '&.Mui-selected': {
                    color: '#fff',
                    backgroundColor: '#b8503a',
                  },
                }}
              />
            </Tabs>
          </AppBar>

          <TabPanel value={tabValue} index={0} dir={theme.direction} >
            <TealHeader patientId={pid} username={user} token={authToken} />
            <TealUtilization patientId={pid} username={user} token={authToken} />
            <TealDiseaseDetection patientId={pid} username={user} token={authToken} />
            <TealScreeners patientId={pid} username={user} token={authToken} />
            <TealMedicationAdherence patientId={pid} username={user} token={authToken} />
            <TealReferral patientId={pid} username={user} token={authToken} />
            <TealQualityDiabetes patientId={pid} username={user} token={authToken} />
            <TealQualityScreening patientId={pid} username={user} token={authToken} />
          </TabPanel>

          <TabPanel value={tabValue} index={1} dir={theme.direction} >
            <CoralHeader patientId={pid} username={user} token={authToken} />
          </TabPanel>

          <Box display="flex" justifyContent="space-between">
            <Button variant="contained" color="primary" onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        </div>
      );
    }

    return (
      <div style={{ maxWidth: widgetWidth + 'px', margin: '0 auto', marginBottom: '50px' }}>
        <TealHeader patientId={pid} username={user} token={authToken} />
        <TealUtilization patientId={pid} username={user} token={authToken} />
        <TealDiseaseDetection patientId={pid} username={user} token={authToken} />
        <TealScreeners patientId={pid} username={user} token={authToken} />
        <TealMedicationAdherence patientId={pid} username={user} token={authToken} />
        <TealReferral patientId={pid} username={user} token={authToken} />
        <TealQualityDiabetes patientId={pid} username={user} token={authToken} />
        <TealQualityScreening patientId={pid} username={user} token={authToken} />
        <Box display="flex" justifyContent="space-between">
          <Button variant="contained" color="primary" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </div>
    );
  }

  if (!authToken || authToken === '') {
    return (
      <div style={{ maxWidth: widgetWidth + 'px', margin: '0 auto' }}>
        <Typography variant="h5">Login</Typography>
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
          <Alert onClose={handleClose} severity="error">
            Login failed
          </Alert>
        </Snackbar>
      </div>
    );
  }
};

export default PatientInfo;
