import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { getCodingPatientTaskReviews } from '../../api';

const TaskReviewInfoModal = ({ username, token, open, handleClose, taskId }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open) {
      setLoading(true);
      getCodingPatientTaskReviews({ username, token }, taskId)
        .then((response) => {
          setReviews(response['reviews'] || []);
        })
        .catch((err) => {
          setError(err.message || 'Error fetching reviews');
        })
        .finally(() => setLoading(false));
    }
  }, [open, taskId]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Review History</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : reviews.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Reviewed By</strong></TableCell>
                  <TableCell><strong>Reviewed At</strong></TableCell>
                  <TableCell><strong>Result</strong></TableCell>
                  <TableCell><strong>Comment</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviews.map((review, index) => (
                  <TableRow
                    key={review.review_id}
                    sx={{
                      backgroundColor: index === 0 ? '#e0f7fa' : 'inherit', // Highlight most recent review
                    }}
                  >
                    <TableCell>{review.reviewed_by}</TableCell>
                    <TableCell>
                      {new Date(review.reviewed_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{review.review_result}</TableCell>
                    <TableCell>{review.review_comment}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No reviews available for this task.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskReviewInfoModal;
