// src/pages/PatientDetails.js
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Divider,
  Box,
  Chip,
  Paper
} from '@mui/material';
import { getCodingFlowVisitDetails } from '../../api';
import useFetch from '../../hooks/useFetch';
import dayjs from 'dayjs';

const statusColors = {
  "Code Added": "#e8f5e9",
  "Code Dismissed": "#ffecec",
  "Code Unattended": "#fff9e6",
  "Existing Code Refreshed": '#e0f7fa',
  "Existing Code UnRefreshed": "#f0faff",
  "No Code Added": "#e0e0e0"
};

const chipStyles = {
  default: { backgroundColor: '#333', color: '#fff' },
  warning: { backgroundColor: '#cc7000', color: '#fff' }
};

const VisitDetails = ({ username, token }) => {
  const [searchParams] = useSearchParams();
  const department = searchParams.get('department');
  const provider = searchParams.get('provider');
  const metric = searchParams.get('metric');

  const [params, setParams] = useState({ username, token, department, provider, metric });
  const { data, loading, error } = useFetch(getCodingFlowVisitDetails, params);

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error.message}</div>;
  if (!data) return null;

  const grouped = data.reduce((acc, row) => {
    const formattedDate = row.encounter_date;
    const key = `${row.patient_id}-${formattedDate}`;
    if (!acc[key]) acc[key] = { patient_id: row.patient_id, encounter_date: formattedDate, actions: [] };
    acc[key].actions.push(row);
    return acc;
  }, {});

  const groupedArray = Object.values(grouped).map(visit => {
    const hasOtherActions = visit.actions.some(action => action.code_status !== 'No Code Added');
    const filteredActions = hasOtherActions
      ? visit.actions.filter(action => action.code_status !== 'No Code Added')
      : visit.actions;
    return { ...visit, actions: filteredActions };
  });

  return (
    <div>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Visit Details – {department} / {provider} / {metric.replaceAll('_', ' ')}
      </Typography>
      <Grid container spacing={3}>
        {groupedArray.map((visit, index) => (
          <Grid item xs={12} key={index}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#e9e9e9' }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                🧍 Patient ID: <strong>{visit.patient_id}</strong>
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 2 }}>
                📅 Encounter Date: <strong>{visit.encounter_date}</strong>
              </Typography>

              <Grid container spacing={2}>
                {visit.actions.map((action, idx) => (
                  <Grid item xs={12} md={6} key={idx}>
                    <Card sx={{ backgroundColor: statusColors[action.code_status] || '#f0f0f0' }} variant="outlined">
                      <CardContent>
                        <Box sx={{ mb: 1 }}>
                          <Chip
                            label={action.code_status}
                            sx={
                              action.code_status === 'Code Unattended' || action.code_status === 'Existing Code UnRefreshed'
                                ? chipStyles.warning
                                : chipStyles.default
                            }
                          />
                        </Box>
                        <Typography variant="body2">
                          <strong>Code Source:</strong> {action.code_source}
                        </Typography>
                        <Typography variant="body2">
                          <strong>HCC Code:</strong> {action.hcc_code || 'N/A'}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Diagnosis Code:</strong> {action.diagnosis_code || 'N/A'}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Diagnosis Desc:</strong> {action.diagnosis_description || 'N/A'}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Last Assessed:</strong> {action.details.recent_dt || 'Never'}
                        </Typography>
                        {Array.isArray(action.details) && action.details.length > 0 && (
                          <Box sx={{ mt: 1, pl: 1, borderLeft: '3px solid #aaa' }}>
                            {action.details.map((detail, dIdx) => (
                              <Box key={dIdx} sx={{ mb: 1 }}>
                                <Typography variant="caption" display="block">
                                  <strong>Detected At:</strong> {detail.detected_at}
                                </Typography>
                                <Typography variant="caption" display="block">
                                  <strong>Detected By:</strong> {detail.detected_by}
                                </Typography>
                                <Typography variant="caption" display="block">
                                  <strong>Message:</strong> {detail.message_content}
                                </Typography>
                                {detail.status && (
                                  <Typography variant="caption" display="block">
                                    <strong>Status:</strong> {detail.status}
                                  </Typography>
                                )}
                                {detail.message_type && (
                                  <Typography variant="caption" display="block">
                                    <strong>Channel:</strong> {detail.message_type}
                                  </Typography>
                                )}
                                {detail.feedback && (
                                  <Typography variant="caption" display="block">
                                    <strong>{detail.feedback_by} ({detail.feedback_at}):</strong> {detail.feedback}
                                  </Typography>
                                )}
                                <Divider sx={{ mt: 1 }} />
                              </Box>
                            ))}
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default VisitDetails;
