import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getCodingFlowDashboard = async (credential) => {
    try {
      const response = await axios.post(`${API_URL}/coding_flow/dashboard`, credential);
      return response.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  };

export const getCodingFlowVisitDetails = async (credential) => {
    try {
      const response = await axios.post(`${API_URL}/coding_flow/details`, credential);
      return response.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  };
