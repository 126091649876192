import React, { useState } from 'react';
import { sendApolloFeedback } from '../../api';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button, MenuItem, Select, InputLabel, FormControl, FormHelperText
} from '@mui/material';

function Feedback({ open, onClose, patientId, username, token, module }) {
  const [feedbackDescription, setFeedbackDescription] = useState('');
  const [reason, setReason] = useState('');
  const [error, setError] = useState({ reason: false, description: false });

  const reasons = [
    '',
    'condition added',
    'never had condition',
    'had condition but now resolved',
    'diagnostic test rules out condition',
    'evidence does not support condition',
    'other actions taken'
  ];

  const handleSubmit = async () => {
    const descriptionRequired = ['evidence does not support', 'diagnostic testing rules out condition', 'other actions taken'];

    const reasonError = reason === '';
    const descriptionError = descriptionRequired.includes(reason) && feedbackDescription.trim() === '';

    if (reasonError || descriptionError) {
      setError({ reason: reasonError, description: descriptionError });
      return;
    }

    const feedbackData = {
      ...module,
      reason,
      feedback_description: feedbackDescription,
    };

    await sendApolloFeedback(patientId, { username, token }, feedbackData);
    setFeedbackDescription('');
    setReason('');
    setError({ reason: false, description: false });
    onClose();
  };

  const handleCancel = () => {
    setFeedbackDescription('');
    setReason('');
    setError({ reason: false, description: false });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{module['title']}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense" error={error.reason}>
          <InputLabel id="reason-label">Select Reason</InputLabel>
          <Select
            labelId="reason-label"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            label="Select Reason"
          >
            {reasons.map((r) => (
              <MenuItem key={r} value={r}>{r}</MenuItem>
            ))}
          </Select>
          {error.reason && <FormHelperText>Please select a reason</FormHelperText>}
        </FormControl>

        <TextField
          margin="dense"
          id="feedbackDescription"
          label={module['feedback_description']}
          type="text"
          fullWidth
          multiline
          rows={4}
          value={feedbackDescription}
          onChange={(e) => setFeedbackDescription(e.target.value)}
          error={error.description}
          helperText={error.description ? 'Feedback is required for selected reason' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default Feedback;

