import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  Chip,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { getCodingPatientList, getCodingMarketCenterPCP, getCodingTaskTypes } from '../../api';
import useFetch from '../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const getUniqueValues = (records, key) => [...new Set(records.map((record) => record[key]))];

const formatTaskList = (taskList) => {
  return (
    <>
      {taskList.map((task, index) => {
        const [taskName, taskValue] = task.split(":");
        const chipColor = taskValue == 0 ? '#D0FFD0' : (taskValue == 2 ? '#FFFFD0' : '#A7C7E7');
        
        return (
          <div key={index}>
            <Chip 
              label={"Review " + taskName} 
              sx={{ backgroundColor: chipColor, marginBottom: 0.2 }} 
            />
            <br />
          </div>
        );
      })}
    </>
  );
};

const PatientList = ({ username, token }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('next_appointment');
  const [market, setMarket] = useState('All');
  const [center, setCenter] = useState('All');
  const [provider, setProvider] = useState('All');
  const [taskType, setTaskType] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [dateFilter, setDateFilter] = useState(null); // Date filter state

  const [allRecords, setAllRecords] = useState([]);
  const [filterOptions, setFilterOptions] = useState({ markets: [], centers: [], providers: [], taskTypes: [] });

  const { data, loading, error } = useFetch(getCodingPatientList, {
    username,
    token,
    page,
    limit: rowsPerPage,
    market,
    center,
    provider,
    taskType,
    sortBy: orderBy,
    sortDirection: order,
    searchTerm,
    nextAppointmentDate: dateFilter ? dayjs(dateFilter).format('YYYY-MM-DD') : "", // Add date filter
    refreshTrigger,
  });

  const navigate = useNavigate();

  const refreshPatientList = () => {
    setRefreshTrigger((prev) => prev + 1); // Increment to trigger data refresh
  };

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const trecords = await getCodingTaskTypes({ username, token });
        const { records } = await getCodingMarketCenterPCP({ username, token });
        setAllRecords(records);
        setFilterOptions((prev) => ({
          ...prev,
          markets: ['All', ...getUniqueValues(records, 'market')],
          centers: ['All', ...getUniqueValues(records, 'center')],
          providers: ['All', ...getUniqueValues(records, 'pcp')],
          taskTypes: trecords.records.length > 0 ? ['All', ...(trecords.records)] : ['All']
        }));

      } catch (error) {
        console.error('Failed to fetch filter data:', error);
      }
    };

    fetchFilters();
  }, [username, token]);

  useEffect(() => {
    const filteredCenters = allRecords.filter((rec) => market === 'All' || rec.market === market);
    setFilterOptions((prev) => ({
      ...prev,
      centers: ['All', ...getUniqueValues(filteredCenters, 'center')],
      providers: ['All'], // Reset providers when market changes
    }));
    setCenter('All');
    setProvider('All');
  }, [market, allRecords]);

  useEffect(() => {
    const filteredProviders = allRecords.filter((rec) =>
      (market === 'All' || rec.market === market) &&
      (center === 'All' || rec.center === center)
    );
    setFilterOptions((prev) => ({
      ...prev,
      providers: ['All', ...getUniqueValues(filteredProviders, 'pcp')],
    }));
    setProvider('All');
  }, [center, market, allRecords]);


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenPatient = (patient) => {
    window.refreshParent = () => {
      setRefreshTrigger((prev) => prev + 1);
    };
    // Open the child window
    window.open(`/coding/${patient.patient_id}`, '_blank');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleTaskTypeChange = (newTaskType) => {
    setTaskType(newTaskType);
    setPage(0);
  };

  const handleDateChange = (newDate) => {
    setDateFilter(newDate);
    setPage(0);
  };

  if (loading) return <Typography variant="h6" align="center">Loading...</Typography>;
  if (error) return <Typography variant="h6" align="center">Error: {error.message}</Typography>;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Paper sx={{ padding: 2, marginTop: 2 }}>

      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel shrink>Market</InputLabel>
            <Select value={market} onChange={(e) => setMarket(e.target.value)} label="Market">
              {filterOptions.markets.map((m) => (
                <MenuItem key={m} value={m}>{m}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel shrink>Center</InputLabel>
            <Select value={center} onChange={(e) => setCenter(e.target.value)} label="Center" disabled={market === 'All'}>
              {filterOptions.centers.map((c) => (
                <MenuItem key={c} value={c}>{c}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel shrink>Provider</InputLabel>
            <Select value={provider} onChange={(e) => setProvider(e.target.value)} label="Provider" disabled={center === 'All'}>
              {filterOptions.providers.map((p) => (
                <MenuItem key={p} value={p}>{p}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            label="Search by Patient ID, Next Appt Provider"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel shrink>Task Type</InputLabel>
            <Select value={taskType} onChange={(e) => handleTaskTypeChange(e.target.value)} label="Task Type">
              {filterOptions.taskTypes.map((p) => (
                <MenuItem key={p} value={p}>Review {p}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
            <DatePicker
              fullWidth
              label="Filter by Next Appointment"
              value={dateFilter}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
            />
          </Grid>
      </Grid>

      

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'teal' }}>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Patient ID</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Patient Name</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>RAF</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Provider</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Task Types</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                <TableSortLabel
                  active={orderBy === 'next_appointment'}
                  direction={orderBy === 'next_appointment' ? order : 'asc'}
                  onClick={() => handleRequestSort('next_appointment')}
                >
                  Next Appointment
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Next Appt Provider</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                <TableSortLabel
                  active={orderBy === 'last_review_date'}
                  direction={orderBy === 'last_review_date' ? order : 'asc'}
                  onClick={() => handleRequestSort('last_review_date')}
                >
                  Last Review Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Last Review User</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.patients?.length > 0 ? (
              data.patients.map((row) => (
                <TableRow key={row.patient_id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' } }}>
                  <TableCell>{row.patient_id}</TableCell>
                  <TableCell>{row.patient_name}</TableCell>
                  <TableCell>{row.raf_score}</TableCell>
                  <TableCell>{row.provider}</TableCell>
                  <TableCell>{formatTaskList(row.task_types)}</TableCell>
                  <TableCell>
                    {row.next_appointment}{' '}
                    <span style={{ fontSize: '0.8em', color: 'gray' }}>
                      {row.next_appointment_time}
                    </span>
                  </TableCell>
                  <TableCell>{row.next_appointment_provider}</TableCell>
                  <TableCell>{row.last_review_date}</TableCell>
                  <TableCell>{row.last_review_user}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => handleOpenPatient(row)}
                      sx={{
                        backgroundColor: row.incompletes === 0 ? 'green' : 'primary.dark',
                        '&:hover': {
                          backgroundColor: row.incompletes === 0 ? 'darkgreen' : 'primary.main',
                        },
                      }}
                    >
                      {row.incompletes === 0 ? 'Completed' : 'Open'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </LocalizationProvider>
  );
};

export default PatientList;
