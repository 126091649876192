import React from 'react';
import { Box, Typography, FormControl, Card, MenuItem, Select, Divider } from '@mui/material';
import { getApolloQualityScreening, setApolloQualityScreeningStatus } from '../../api';
import useFetch from '../../hooks/useFetch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

const TextWithLineBreaks = ({ text }) => {
    return (
      <div>
        {text.split('<LB>').map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index !== text.split('<LB>').length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>
    );
  };

const TealQualityScreening = ({ patientId, username, token }) => {
    const { data: patient, loading, error } = useFetch(getApolloQualityScreening, patientId, { username, token });
    const [screeningGaps, setScreeningGaps] = React.useState([]);

    React.useEffect(() => {
        if (patient && patient.ScreeningGaps) {
            setScreeningGaps(patient.ScreeningGaps);
        }
    }, [patient]);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error: {error.message}</Typography>;

    const handleScreeningGaps = (event, measure, hedis_year) => {
        setApolloQualityScreeningStatus(patientId, { username, token }, measure, hedis_year, 'ACK');
        setScreeningGaps(prevState => prevState.map(gap =>
            gap.HedisYear === hedis_year && gap.MeasureCode === measure ? { ...gap, Status: 'ACK' } : gap
        ));
    };

    if (patient.Active === 0) {
        return (<div></div>)
    }

    let screeningGapComponent = ""

    let screeningInfo = ""

    let hasInfo = 0
    if (patient.Active === 1) {
        hasInfo = 1
        screeningGapComponent = (
            <div>
                {screeningGaps.map(gap => {
                    screeningInfo = gap.ScreeningInfo

                    return (
                        <div>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                bgcolor="teal"
                                color="white"
                                p={1}
                                borderRadius="4px 4px 0 0"
                            >
                                {gap.Title}
                            </Box>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow key={gap.HedisYear+gap.MeasureCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ padding: '4px' }} component="th" scope="row">
                                                <TextWithLineBreaks text={screeningInfo} />
                                            </TableCell>
                                            <TableCell sx={{ padding: '4px' }} align="right">
                                                <FormControl variant="standard" sx={{ m: 0, minWidth: 46 }}>
                                                <Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                    onChange={(event) => handleScreeningGaps(event, gap.MeasureCode, gap.HedisYear)}
                                                />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Divider sx={{ height: 10 }} />
                        </div>
                    )
                }
                )}
                </div>
        )
    }

    if (hasInfo === 0) {
        return (<></>)
    }
    return (
        <div>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ backgroundColor: '#d5dbdb', margin: '0 !important', minHeight: 'unset !important', '& .MuiAccordionSummary-content': { margin: '5px 0 !important' } }}
                >
                    <Typography variant="h6">Quality - Screenings</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#eaeded', padding: '12px' }}>
                    {screeningGapComponent}
                </AccordionDetails>
            </Accordion>
            <Divider sx={{ height: 15 }} />
        </div>
    );
}

export default TealQualityScreening;
