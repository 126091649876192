import React from 'react';
import { Box, Typography, FormControl, Card, MenuItem, Select, Divider } from '@mui/material';
import { getApolloScreeners, setApolloScreenersStatus } from '../../api';
import useFetch from '../../hooks/useFetch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

const TextWithLineBreaks = ({ text }) => {
    return (
      <div>
        {text.split('<LB>').map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index !== text.split('<LB>').length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>
    );
  };

const TealScreeners = ({ patientId, username, token }) => {
    const { data: patient, loading, error } = useFetch(getApolloScreeners, patientId, { username, token });
    const [annualBundleGaps, setAnnualBundleGaps] = React.useState([]);

    React.useEffect(() => {
        if (patient && patient.AnnualBundleGaps) {
            setAnnualBundleGaps(patient.AnnualBundleGaps);
        }
    }, [patient]);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error: {error.message}</Typography>;

    const handleAnnualBundleGaps = (event, AnniversaryDate) => {
        setApolloScreenersStatus(patientId, { username, token }, 'Annal_Bundle', AnniversaryDate, 'ACK');
        setAnnualBundleGaps(prevState => prevState.map(gap =>
            gap.AnniversaryDate === AnniversaryDate ? { ...gap, Status: 'ACK' } : gap
        ));
    };

    if (patient.Active === 0) {
        return (<div></div>)
    }

    let annualBundleGapComponent = ""

    let screeningInfo = ""

    let hasInfo = 0
    if (patient.Active === 1) {
        hasInfo = 1
        annualBundleGapComponent = (
            <div>
                {annualBundleGaps.map(gap => {
                    let dueColor = "#FFFFFF"
                    if (gap.IsPastDue == 1) {
                        dueColor = "#fdedec"
                    }

                    return (
                        <div>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                bgcolor="teal"
                                color="white"
                                p={1}
                                borderRadius="4px 4px 0 0"
                            >
                                {"Annual Bundle"}
                            </Box>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow key={gap.AnniversaryDate} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ padding: '4px', bgcolor: dueColor }} component="th" scope="row">
                                                Due on {gap.AnniversaryDate}
                                            </TableCell>
                                            <TableCell sx={{ padding: '4px', bgcolor: dueColor }} align="right">
                                                <FormControl variant="standard" sx={{ m: 0, minWidth: 46 }}>
                                                <Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                    onChange={(event) => handleAnnualBundleGaps(event, gap.AnniversaryDate)}
                                                />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Divider sx={{ height: 10 }} />
                        </div>
                    )
                }
                )}
                </div>
        )
    }

    if (hasInfo === 0) {
        return (<></>)
    }
    return (
        <div>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ backgroundColor: '#d5dbdb', margin: '0 !important', minHeight: 'unset !important', '& .MuiAccordionSummary-content': { margin: '5px 0 !important' } }}
                >
                    <Typography variant="h6">Screeners</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#eaeded', padding: '12px' }}>
                    {annualBundleGapComponent}
                </AccordionDetails>
            </Accordion>
            <Divider sx={{ height: 15 }} />
        </div>
    );
}

export default TealScreeners;
