import React from 'react';
import { Box, Typography, Divider, Select, MenuItem  } from '@mui/material';
import { getApolloHeaderCoral } from '../../api';
import { useState, useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import { setApolloHeaderStatus }  from '../../api';

const Header = ({ patientId, username, token }) => {

    const { data: patient, loading, error } = useFetch(getApolloHeaderCoral, patientId, { username, token});
    const [priority, setPriority] = useState('');

    useEffect(() => {
      if (patient && patient.Priority) {
        setPriority(patient.Priority);
      }
    }, [patient]);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error: {error.message}</Typography>;
    if (!patient.PatientID) return null;

    // Handle select change
    const handlePriorityChange = (event) => {
      setApolloHeaderStatus(patientId, { username, token}, event.target.value)
      setPriority(event.target.value);
    };

    return (
        <div>
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bgcolor="#b8503a"
            color="white"
            p={1.5}
            borderRadius="4px 4px 0 0"
          >
            <Box display="flex" alignItems="center">
              <Box
                component="img"
                src="/icon.jpg" // Replace with the path to your icon
                alt="Icon"
                width={25}
                height={40}
                mr={1}
              />
              <Box>
                <Typography variant="body1">{patient.PatientName}<br />#{patient.PatientID}</Typography>
              </Box>
            </Box>
            <Box textAlign="left" sx={{ minWidth: 78, ml: 1 }}>
              <Typography variant="body2" sx={{ height: '20px' }}>Disease Detection: {patient.CoderSuggestionCount}</Typography>
              <Typography variant="body2" sx={{ height: '24px' }}>Missing Screeners: {patient.MissingScreenerCount}</Typography>
            </Box>
            <Box textAlign="left">
            </Box>
        </Box>
        <Divider />
        <Box my={0}>
          <Typography variant="body2">Recommended Next Appointment: {patient.RecommendedNextAppt}</Typography>
        </Box>
        <Divider sx={{ height:15 }}/>
        </div>
    )
}

export default Header;
