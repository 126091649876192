import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Link, CircularProgress, FormControlLabel, Switch,
  Box, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';

import { getCodingFlowDashboard } from '../../api';
import useFetch from '../../hooks/useFetch';

const columnStyles = {
  existing_codes: { backgroundColor: '#e0f7fa' },
  existing_unrefreshed_codes: { backgroundColor: '#f0faff' },
  new_codes: { backgroundColor: '#e8f5e9' },
  doctor_codes: { backgroundColor: '#f1fdf2' },
  coder_codes: { backgroundColor: '#f1fdf2' },
  dismissed_codes: { backgroundColor: '#ffecec' },
  unattended_codes: { backgroundColor: '#fff9e6' }
};

function blendColors(c1, c2, weight = 0.5) {
  const hexToRgb = (hex) => {
    const parsed = hex.replace('#', '');
    return [
      parseInt(parsed.substring(0, 2), 16),
      parseInt(parsed.substring(2, 4), 16),
      parseInt(parsed.substring(4, 6), 16)
    ];
  };
  const rgbToHex = (rgb) => {
    return (
      '#' +
      rgb
        .map((x) => {
          const hex = Math.round(x).toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('')
    );
  };
  const r1 = hexToRgb(c1);
  const r2 = hexToRgb(c2);
  const blended = r1.map((v, i) => v * weight + r2[i] * (1 - weight));
  return rgbToHex(blended);
}

const Dashboard = ({ username, token }) => {
  const [days, setDays] = useState(7);
  const [params, setParams] = useState({ username, token, days });
  const [normalize, setNormalize] = useState(false);

  useEffect(() => {
    setParams({ username, token, days });
  }, [days, username, token]);

  const { data, loading, error } = useFetch(getCodingFlowDashboard, params);

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error.message}</div>;
  if (!data) return null;

  const grouped = data.reduce((acc, row) => {
    acc[row.department] = acc[row.department] || [];
    acc[row.department].push(row);
    return acc;
  }, {});

  const grandTotal = {
    patients_visits: 0,
    visits_with_new_codes: 0,
    visits_without_new_codes: 0,
    new_codes: 0,
    existing_codes: 0,
    existing_unrefreshed_codes: 0,
    doctor_codes: 0,
    coder_codes: 0,
    dismissed_codes: 0,
    unattended_codes: 0,
  };

  data.forEach((row) => {
    Object.keys(grandTotal).forEach((key) => {
      grandTotal[key] += row[key];
    });
  });

  const formatMetric = (value, visits) => {
    return normalize && visits > 0 ? ((value / visits) * 1000).toFixed(0) : value;
  };

  const metricFields = [
    'patients_visits',
    'visits_with_new_codes',
    'visits_without_new_codes',
    'existing_codes',
    'existing_unrefreshed_codes',
    'new_codes',
    'doctor_codes',
    'coder_codes',
    'dismissed_codes',
    'unattended_codes'
  ];

  return (
    <Box height="87vh" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2, px: 2, pt: 2 }}>
        <Typography variant="h5">HCC Summary</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControlLabel
            control={<Switch checked={normalize} onChange={() => setNormalize(!normalize)} />}
            label="Normalize per 1000 Visits"
          />
          <FormControl size="small">
            <InputLabel id="day-range-label">Days</InputLabel>
            <Select
              labelId="day-range-label"
              value={days}
              label="Days"
              onChange={(e) => setDays(Number(e.target.value))}
            >
              <MenuItem value={7}>Last 7 Days</MenuItem>
              <MenuItem value={30}>Last 30 Days</MenuItem>
              <MenuItem value={90}>Last 90 Days</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#fff', top: 0, zIndex: 1 }}>Department</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#fff', top: 0, zIndex: 1 }}>Provider</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#fff', top: 0, zIndex: 1 }}>Patient Visits</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#fff', top: 0, zIndex: 1 }}>With New Codes</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#fff', top: 0, zIndex: 1 }}>Without New Codes</TableCell>
              <TableCell sx={{ fontWeight: 'bold', ...columnStyles.existing_codes, top: 0, zIndex: 1 }}>Existing Codes</TableCell>
              <TableCell sx={{ fontWeight: 'bold', ...columnStyles.existing_unrefreshed_codes, top: 0, zIndex: 1 }}>Unassessed</TableCell>
              <TableCell sx={{ fontWeight: 'bold', ...columnStyles.new_codes, top: 0, zIndex: 1 }}>New Codes</TableCell>
              <TableCell sx={{ fontWeight: 'bold', ...columnStyles.doctor_codes, top: 0, zIndex: 1 }}>Doctor</TableCell>
              <TableCell sx={{ fontWeight: 'bold', ...columnStyles.coder_codes, top: 0, zIndex: 1 }}>Coder</TableCell>
              <TableCell sx={{ fontWeight: 'bold', ...columnStyles.dismissed_codes, top: 0, zIndex: 1 }}>Dismissed</TableCell>
              <TableCell sx={{ fontWeight: 'bold', ...columnStyles.unattended_codes, top: 0, zIndex: 1 }}>Unattended</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#e0e0e0' }}>
                <Typography variant="h6">Grand Total</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0' }}></TableCell>
              {metricFields.map((key) => (
                <TableCell
                  key={key}
                  sx={{
                    fontWeight: 'bold',
                    backgroundColor: columnStyles[key]?.backgroundColor
                      ? blendColors(columnStyles[key].backgroundColor, '#e0e0e0')
                      : '#e0e0e0'
                  }}
                >
                  {key === 'patients_visits'
                    ? grandTotal[key]
                    : formatMetric(grandTotal[key], grandTotal['patients_visits'])}
                </TableCell>
              ))}
            </TableRow>

            {Object.entries(grouped).map(([department, providers]) => {
              const totals = Object.fromEntries(metricFields.map((key) => [key, 0]));

              providers.forEach((p) => {
                metricFields.forEach((key) => {
                  totals[key] += p[key];
                });
              });

              return (
                <React.Fragment key={department}>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#f5f5f5' }}>
                      <Typography variant="h6">{department}</Typography>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}></TableCell>
                    {metricFields.map((key) => (
                      <TableCell
                        key={key}
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: columnStyles[key]?.backgroundColor
                            ? blendColors(columnStyles[key].backgroundColor, '#f5f5f5')
                            : '#f5f5f5'
                        }}
                      >
                        {key === 'patients_visits'
                          ? totals[key]
                          : formatMetric(totals[key], totals['patients_visits'])}
                      </TableCell>
                    ))}
                  </TableRow>

                  {providers.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell></TableCell>
                      <TableCell>{row.provider}</TableCell>
                      {metricFields.map((field) => (
                        <TableCell key={field} sx={columnStyles[field] || {}}>
                          {field === 'patients_visits' ? (
                            row[field]
                          ) : (
                          Number(days) == 7 ? (
                              <Link
                                underline="none"
                                href={`/coding_flow/visitdetails?department=${encodeURIComponent(
                                  row.department
                                )}&provider=${encodeURIComponent(row.provider)}&metric=${field}`}
                                target="_blank"
                              >
                                {formatMetric(row[field], row['patients_visits'])}
                              </Link>
                            ) : (
                              formatMetric(row[field], row['patients_visits'])
                            )
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Dashboard;