import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
} from '@mui/material';
import { getCodingTaskQueue, deleteCodingApolloDiseaseDetection } from '../../api';
import useFetch from '../../hooks/useFetch';
import TaskModal from './TaskModal';
import TaskReviewInfoModal from './TaskReviewInfoModal'; // Import the new modal

const formatTaskDetail = (diseaseType, taskJson) => {

  let info = (<></>)
  if (diseaseType == 'Unlinked Apollo Messages') {
    return info;
  }
  if (diseaseType === 'Morbid Obesity') {
    const comorbidity = taskJson.comorbidity ? (
      <>
        <strong>CM:</strong> {taskJson.comorbidity} <br />
      </>
    ) : null;

    info = <Typography variant="body2" component="div">
            <strong>Date:</strong> {taskJson.bmi_date} <br />
            <strong>BMI:</strong> {taskJson.bmi_value} <br />
            {comorbidity}
          </Typography>
  }

  if (diseaseType === 'Thrombocytosis' || diseaseType === 'Thrombocytopenia') {
    info = <Typography variant="body2" component="div">
            <strong>Date:</strong> {taskJson.platelet_count_date} <br />
            <strong>Platelet Count:</strong> {taskJson.platelet_count_value} <br />
          </Typography>
  }

  if (diseaseType === 'Depression') {
    info = <Typography variant="body2" component="div">
            <strong>Date:</strong> {taskJson.phq9_date} <br />
            <strong>PHQ9 / PHQ2 Score:</strong> {taskJson.phq9_score} <br />
          </Typography>
  }

  if (diseaseType === 'Mini-COG/Dementia') {
    info = <Typography variant="body2" component="div">
            <strong>Date:</strong> {taskJson.minicog_date} <br />
            <strong>Mini-COG Score:</strong> {taskJson.minicog_score} <br />
          </Typography>
  }

  if (diseaseType === 'PVD Complications') {
    info = <Typography variant="body2" component="div">
            <strong>PVD Date:</strong> {taskJson.evidence_dos} <br />
            <strong>PVD Evidence:</strong> {taskJson.evidence} <br />
            <strong>Complications:</strong> {taskJson.complication} <br />
          </Typography>
  }

  if (diseaseType === 'Pancytopenia') {
    info = <Typography variant="body2" component="div">
            <strong>CBC Date:</strong> {taskJson.cbc_date} <br />
            <strong>CBC Item:</strong> {taskJson.cbc_observation_identifier} <br />
            <strong>Item Value:</strong> {taskJson.cbc_value} <br />
          </Typography>
  }

  if (diseaseType === 'Atherosclerosis of Extremities with Rest Pain') {
    info = <Typography variant="body2" component="div">
            <strong>Quantaflo Date:</strong> {taskJson.quantaflo_date} <br />
            <strong>Extremities:</strong> {taskJson.quantaflo_extremities} <br />
            <strong>Test Value:</strong> {taskJson.quantaflo_value} <br />
          </Typography>
  }

  if (diseaseType === 'Cyclic Neutropenia') {
    info = <Typography variant="body2" component="div">
            <strong>CBC Date:</strong> {taskJson.cbc_date} <br />
            <strong>CBC Item:</strong> {taskJson.cbc_observation_identifier} <br />
            <strong>Item Value:</strong> {taskJson.cbc_value} <br />
          </Typography>
  }

  if (diseaseType === 'Non Captured HCC') {
    info = <Typography variant="body2" component="div">
            <strong>Date:</strong> {taskJson.detected_date} <br />
            <strong>Desc:</strong> {taskJson.description} <br />
            <strong>From:</strong> {taskJson.source}
          </Typography>
  }
  if (diseaseType === 'Dismissed Recommendation') {
    info = <Typography variant="body2" component="div">
            <strong>{taskJson.hcc_version}:</strong> {taskJson.hcc} <br />
            <strong>{taskJson.created_by}:</strong> {taskJson.source_text} <br />
            <strong>{taskJson.dismissed_by}:</strong> {taskJson.dismissal_reason} <br />
          </Typography>
  }

  if (diseaseType === 'Upcoming Appointment') {
    info = <Typography variant="body2" component="div">
            <strong>Date:</strong> {taskJson.description} <br />
          </Typography>
  }

  if (diseaseType === 'Allymar') {
    info = <Typography variant="body2" component="div">
            <strong>Description:</strong> {taskJson.description} <br />
            <strong>Evidence:</strong> {taskJson.evidence} ({taskJson.evidence_dos}) <br />
            <strong>Explanation:</strong> {taskJson.explanation} <br />
          </Typography>
  }
  
  if (diseaseType.substring(0, 4) === 'Meds') {
    info = <Typography variant="body2" component="div">
            <strong>Evidence:</strong> {taskJson.evidence} ({taskJson.evidence_dos}) <br />
          </Typography>
  }

  if (diseaseType === 'Spirometry/COPD' || diseaseType === 'eGFR/CKD' || diseaseType === 'A1C/Diabetes' || diseaseType === 'Echo/Heart Failure' ||  diseaseType === 'EKG/Afib' ) {
    info = <Typography variant="body2" component="div">
            <strong>Evidence:</strong> {taskJson.evidence} <br />
          </Typography>
  }

  return (
    <div>
      <Box
        sx={{
          backgroundColor: '#A7C7E7',
          borderRadius: '8px',
          display: 'inline-block',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.15)', // Soft shadow for depth
          fontFamily: 'Arial, sans-serif',
          overflow: 'hidden', // Ensures the border radius applies fully
          width: '400px', // Set width to 400px
        }}
      >
        <div style={{ padding: '12px 18px' }}>
        {info}
        </div>
      </Box>
      <br />
    </div>
  );
};

const formatApolloMessage = (message, handleDeleteMessage) => {
  const info = (
    <>
      <div
        style={{
          backgroundColor: '#81C784', // Different pastel green for header
          padding: '8px 12px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          color: '#333',
          position: 'relative', // For positioning the "X" in the top-right corner
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Apollo Message ({message.DiagnosisCode}/{message.HCC})
        <span
          onClick={() => handleDeleteMessage(message)}
          style={{
            position: 'absolute',
            top: '4px',
            right: '8px',
            cursor: 'pointer',
            padding: '4px',
            color: '#555',
            borderRadius: '4px',
            fontWeight: 'bold',
            transition: 'background-color 0.3s',
          }}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#E57373')}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
        >
          ✕
        </span>
      </div>
      <div style={{ padding: '12px 18px' }}>
        <div><strong>{message.created_by}:</strong> {message.Description}</div>
        <div
            style={{
              display: 'flex',
            }}
          >
            <div style={{ color: 'grey', marginLeft: 'auto', fontSize: '12px' }}>
              {new Date(message.created_at).toLocaleString()}
            </div>
          </div>
        {message.feedback_by && (
          <div
            style={{
              display: 'flex',
              marginTop: '12px',
              padding: '10px',
              backgroundColor: '#FFECB3', // Light yellow background for feedback section
              borderRadius: '8px',
              border: '1px solid #FFB74D',
            }}
          >
            <div><strong>{message.feedback_by}:</strong> {message.feedback}</div>
            <div style={{ color: 'grey', marginLeft: 'auto', fontSize: '12px' }}>
              {new Date(message.feedback_at).toLocaleString()}
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div>
      <Box
        sx={{
          backgroundColor: '#C5E1A5', // Pastel light green for main content
          borderRadius: '8px',
          display: 'inline-block',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.15)', // Soft shadow for depth
          fontFamily: 'Arial, sans-serif',
          color: '#333', // Dark text for readability
          overflow: 'hidden', // Ensures the border radius applies fully
          width: '400px', // Set width to 400px
        }}
      >
        {info}
      </Box>
      <br />
    </div>
  );
};



const TaskQueue = ({ username, token, patientId }) => {
  const [params, setParams] = useState({
    username,
    token,
    patientId,
  });
  const [openModal, setOpenModal] = useState(false);
  const [openReviewInfoModal, setOpenReviewInfoModal] = useState(false); // State for TaskReviewInfoModal
  const [selectedTask, setSelectedTask] = useState([null, '']);
  const [selectedTaskId, setSelectedTaskId] = useState(null); // Store the taskId for review info modal
  const [refresh, setRefresh] = useState(false);

  const { data, loading, error } = useFetch(getCodingTaskQueue, params);

  useEffect(() => {
    console.log(refresh);
    setParams((prev) => ({ ...prev, refresh }));
  }, [refresh]);

  const handleOpenModal = (task, action) => {
    setSelectedTask([task, action]);
    setOpenModal(true);
  };

  const handleDeleteMessage = async (message) => {
    await deleteCodingApolloDiseaseDetection({
      username,
      token,
      patientId,
    }, message);
    handleRefresh();
  };

  const handleOpenReviewInfoModal = (taskId) => {
    setSelectedTaskId(taskId);
    setOpenReviewInfoModal(true);
  };

  const handleCloseModal = () => {
    if (window.opener && typeof window.opener.refreshParent === 'function') {
      window.opener.refreshParent();
    }
    setOpenModal(false);
    setSelectedTask([null, '']);
    handleRefresh();
  };

  const handleCloseReviewInfoModal = () => {
    setOpenReviewInfoModal(false);
    setSelectedTaskId(null);
  };

  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  if (loading) return <Typography variant="h6" align="center">Loading...</Typography>;
  if (error) return <Typography variant="h6" align="center">Error: {error.message}</Typography>;

  return (
    <Paper sx={{ padding: 2, marginTop: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'teal' }}>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Task Type</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>HCC</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Risk Score</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Diagnoses</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Evidence Details</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Recommendation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.tasks?.length > 0 ? (
              data.tasks.map((row) => (
                <TableRow key={row.task_id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' } }}>
                  <TableCell>Review {row.disease_type}</TableCell>
                  <TableCell>{row.task_json_obj.hcc}</TableCell>
                  <TableCell>{row.task_json_obj.score}</TableCell>
                  <TableCell>{row.task_json_obj.diagnosis_code}</TableCell>
                  <TableCell>
                    {formatTaskDetail(row.disease_type, row.task_json_obj)}
                    {row.messages.map((msg) => (
                        formatApolloMessage(msg, handleDeleteMessage)
                      ))
                    }
                  </TableCell>
                  <TableCell>
                    {row.review_result && row.task_id != 'noaction' ? (
                      <Button
                        variant="contained"
                        onClick={() => handleOpenReviewInfoModal(row.task_id)}
                        sx={{
                          marginBottom: 0.5,
                          width: '150px',
                          backgroundColor: row.review_result === 'everything good' || row.review_result === 'add code' || row.review_result === 'dismissed' ? 'darkgreen' : '#FFD700',
                          '&:hover': {
                            backgroundColor: row.review_result === 'everything good' || row.review_result === 'add code' || row.review_result === 'dismissed' ? 'green' : '#FFC107',
                          },
                          color: row.review_result === 'everything good' || row.review_result === 'add code' || row.review_result === 'dismissed' ? 'white' : 'black', // Adjust text color for contrast
                        }}
                      >
                        {row.review_result}
                      </Button>
                    ) : (
                      <></>
                    )}
                    {row.review_result && row.task_id != 'noaction' ? (<br />) : (<></>)}
                    {row.task_id != 'noaction' ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenModal(row, 'accept')}
                          sx={{
                            width: '150px',
                          }}
                        >
                          Add Review
                        </Button>
                      </>
                      ) : (
                        <></>
                      )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TaskModal
        username={username}
        token={token}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        selectedTask={selectedTask}
      />
      <TaskReviewInfoModal
        username={username}
        token={token}
        open={openReviewInfoModal}
        handleClose={handleCloseReviewInfoModal}
        taskId={selectedTaskId}
      />
    </Paper>
  );
};

export default TaskQueue;
